import { MinusIcon } from '@pluralsight/icons';
import { FlexContainer, FlexItem } from '@pluralsight/react-ng';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import './navigation-item.scss';
import { ITEM_TO_ICON_MAP } from './constants';

interface NavigationItemProps {
  id: string;
  name: string;
  path: string;
  tabIndex?: number;
}

const NavigationItem: React.FC<NavigationItemProps> = ({
  id,
  name,
  path,
  tabIndex,
}) => {
  const location = useLocation();

  const isActive = location.pathname === `${path}`;

  return (
    <Link to={path} tabIndex={tabIndex} aria-label={name}>
      <FlexContainer
        className={isActive ? 'navigation-item is-active' : 'navigation-item'}
        role="button"
      >
        <FlexItem className="navigation-item__icon">
          {ITEM_TO_ICON_MAP[id] || <MinusIcon aria-hidden="true" />}
        </FlexItem>
        <FlexItem className="navigation-item__label">{name}</FlexItem>
      </FlexContainer>
    </Link>
  );
};

export default NavigationItem;
