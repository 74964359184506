import { FlexContainer } from '@pluralsight/react-ng';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PluralsightLogo from '../../../../assets/images/logo.svg';
import './footer.scss';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer__container">
      <FlexContainer
        align="stretch"
        direction="row"
        justify="spaceBetween"
        wrap="nowrap"
        className="footer__container__content"
        gap={24}
      >
        <FlexContainer align="center" gap="16px">
          <img src={PluralsightLogo} alt="" />
          <span className="footer__container__logo">
            {t('footer.copyright', { currentYear })}
          </span>
        </FlexContainer>
        <FlexContainer
          className="footer__container__company-info"
          align="center"
          direction="row"
          justify="flexEnd"
          wrap="nowrap"
          gap="40px"
        >
          <a
            href="https://www.pluralsight.com/terms"
            target="_blank"
            role="link"
            className="footer__container__link"
          >
            {t('footer.termsOfUse')}
          </a>
          <a
            href="https://www.pluralsight.com/privacy"
            target="_blank"
            role="link"
            className="footer__container__link"
          >
            {t('footer.privacyPolicy')}
          </a>
        </FlexContainer>
      </FlexContainer>
    </footer>
  );
};

export default Footer;
