import type { LifeCycles } from 'single-spa';

export type AppLifeCycles = LifeCycles<{ domElement: HTMLElement }>;

export enum ENavigationOption {
  LEADER = 'leader',
  LEARNER = 'learner',
}

export enum USER_ROLE {
  SYSTEM_ADMIN = 'System Admin',
  SSO_ADMIN = 'SSO Admin',
  SUPER_ADMIN = 'Super Admin',
  PLAN_ADMIN = 'Plan Admin',

  MANAGER_FULL = 'Manager Full',
  MANAGER_LIMITED = 'Manager Limited',
  MANAGER_BASIC = 'Manager Basic',

  API_DEVELOPER = 'API developer',
  B2B_LEARNER = 'B2B learner',
  B2C_LEARNER = 'B2C learner',
}
