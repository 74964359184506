// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawer-container__button {
  align-items: center;
  border-radius: var(--pando-radii-md-border-radius);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--pando-spacing-1) var(--pando-spacing-2);
}
.drawer-container__button:hover {
  background-color: var(--pando-colors-neutral-border-100);
}
.drawer-container__content {
  position: absolute;
  display: none;
  top: 0;
  right: 0;
  width: var(--pando-spacing-60);
  height: calc(100vh - var(--header-height));
  z-index: var(--pando-z-index-overlay);
  overflow-y: auto;
  padding-bottom: var(--pando-spacing-2);
  background-color: var(--pando-colors-neutral-surface-200);
}
.drawer-container__content.full-width {
  width: 100vw;
}
.drawer-container__content.open {
  display: block;
}
.drawer-container.open .drawer-container__button {
  background-color: var(--pando-colors-neutral-border-100);
}`, "",{"version":3,"sources":["webpack://./src/components/drawer/drawer.scss"],"names":[],"mappings":"AAEE;EACE,mBAAA;EACA,kDAAA;EACA,eAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,sDAAA;AADJ;AAGI;EACE,wDAAA;AADN;AAKE;EACE,kBAAA;EACA,aAAA;EACA,MAAA;EACA,QAAA;EACA,8BAAA;EACA,0CAAA;EACA,qCAAA;EACA,gBAAA;EACA,sCAAA;EACA,yDAAA;AAHJ;AAKI;EACE,YAAA;AAHN;AAMI;EACE,cAAA;AAJN;AASI;EACE,wDAAA;AAPN","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.drawer-container {\n  &__button {\n    align-items: center;\n    border-radius: var(--pando-radii-md-border-radius);\n    cursor: pointer;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    padding: var(--pando-spacing-1) var(--pando-spacing-2);\n\n    &:hover {\n      background-color: var(--pando-colors-neutral-border-100);\n    }\n  }\n\n  &__content {\n    position: absolute;\n    display: none;\n    top: 0;\n    right: 0;\n    width: var(--pando-spacing-60);\n    height: calc(100vh - var(--header-height));\n    z-index: var(--pando-z-index-overlay);\n    overflow-y: auto;\n    padding-bottom: var(--pando-spacing-2);\n    background-color: var(--pando-colors-neutral-surface-200);\n\n    &.full-width {\n      width: 100vw;\n    }\n\n    &.open {\n      display: block;\n    }\n  }\n\n  &.open {\n    .drawer-container__button {\n      background-color: var(--pando-colors-neutral-border-100);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
