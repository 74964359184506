// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO (@przem-wierzbicki): THIS IS TEMP. REMOVE SOON
export const SECRET_KEY = 'SECRET_KEY';

// TODO (przem-wierzbicki): MOVE TO SHARED-PACKAGES
export const DOMAIN = 'COMMON';
const getConfig = (domain: string = DOMAIN) => {
  return {
    [DOMAIN]: window.NEXTGEN_CONFIG[domain],
    COMMON: window.NEXTGEN_CONFIG['COMMON'],
  };
};

export const CONFIGURATION = getConfig(DOMAIN);
