import {
  BellIcon,
  BookmarkIcon,
  ChatMessageLinesIcon,
  GearIcon,
  HelpCircleIcon,
  HistoryIcon,
  PersonIcon,
  TrophyIcon,
} from '@pluralsight/icons';
import React from 'react';

import type { ReactElement } from 'react';

export const ITEM_TO_ICON_MAP: { [key: string]: ReactElement } = {
  'account-settings': <GearIcon aria-hidden="true" />,
  bookmarks: <BookmarkIcon aria-hidden="true" />,
  history: <HistoryIcon aria-hidden="true" />,
  profile: <PersonIcon aria-hidden="true" />,
  'send-feedback': <ChatMessageLinesIcon aria-hidden="true" />,
  'trophy-case': <TrophyIcon aria-hidden="true" />,
  notifications: <BellIcon aria-hidden="true" />,
  'help-center': <HelpCircleIcon aria-hidden="true" />,
};
