import { For, Show } from '@pluralsight/react-ng';
import React, { useEffect, useRef, useState } from 'react';
import {
  BellIcon,
  CloseIcon,
  ExternalLinkIcon,
  HelpCircleIcon,
  MenuIcon,
  MinusIcon,
  PersonIcon,
  SearchIcon,
  SignoutIcon,
} from '@pluralsight/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import {
  PandoBreakpointsLg,
  PandoBreakpointsSm,
} from '@pluralsight/design-tokens';
import cs from 'classnames';

import Drawer from '../../../../components/drawer/drawer';
import SkillsLogo from '../../../../assets/images/skills-logo.svg';
import MobileSkillsLogo from '../../../../assets/images/mobile-skills-logo.svg';
import OptionsDropdown from '../../../../components/options-dropdown/options-dropdown';
import './main-header.scss';
import {
  selectAccount,
  selectHelp,
  selectIris,
  selectNotifications,
  selectSearch,
} from '../../../navigation/state/selectors';
import { getNavigation } from '../../../navigation/state/slice';
import useAppLogout from '../../hooks/useAppLogout';
import Search from '../search/search';
import Navigation from '../../../navigation/components/navigation/navigation';

import { ITEM_TO_ICON_MAP } from './constants';
import { getAccountList, shouldShowDivider } from './helpers';

// TODO (przem-wierzbicki): DECOMPOSE INTO SUBCOMPONENTS
const MainHeader = () => {
  const [isHelpDropdownOpen, setIsHelpDropdownOpen] = useState(false);
  const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
  const [isSearchDropdownOpen, setIsSearchDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleLogout } = useAppLogout();
  const location = useLocation();

  const helpDropdownRef = useRef<HTMLDivElement | null>(null);
  const helpOptionRef = useRef<HTMLAnchorElement | null>(null);
  const accountDropdownRef = useRef<HTMLDivElement | null>(null);
  const accountOptionRef = useRef<HTMLAnchorElement | null>(null);
  const searchDropdownRef = useRef<HTMLDivElement | null>(null);

  const isDesktopScreen = useMediaQuery({
    query: `(min-width: ${PandoBreakpointsLg}`,
  });

  const isMobileScreen = useMediaQuery({
    query: `(max-width: ${PandoBreakpointsSm}`,
  });

  const handleCloseHelpDropdown = () => setIsHelpDropdownOpen(false);
  const handleCloseAccountDropdown = () => setIsAccountDropdownOpen(false);
  const handleCloseSearchDropdown = () => setIsSearchDropdownOpen(false);

  const handleClickOnAccountLink = () => {
    handleCloseHelpDropdown();

    if (isMobileScreen) handleCloseAccountDropdown();
  };

  const handleDropdownKeyDownEvent = (event: KeyboardEvent) => {
    if (
      helpDropdownRef.current &&
      (event.target as HTMLButtonElement).id === 'options-dropdown-help' &&
      event.key === 'Enter'
    ) {
      setIsHelpDropdownOpen(true);

      if (helpOptionRef.current) helpOptionRef.current.focus();
    }

    if (helpDropdownRef.current && event.key === 'Escape') {
      handleCloseHelpDropdown();
    }

    if (
      accountDropdownRef.current &&
      (event.target as HTMLButtonElement).id === 'options-dropdown-account' &&
      event.key === 'Enter'
    ) {
      setIsAccountDropdownOpen(true);

      if (accountOptionRef.current) accountOptionRef.current.focus();
    }

    if (accountDropdownRef.current && event.key === 'Escape') {
      handleCloseAccountDropdown();
    }

    if (
      searchDropdownRef.current &&
      (event.target as HTMLButtonElement).id === 'options-dropdown-search' &&
      event.key === 'Enter'
    ) {
      setIsSearchDropdownOpen(true);

      if (searchDropdownRef.current) searchDropdownRef.current.focus();
    }

    if (searchDropdownRef.current && event.key === 'Escape') {
      handleCloseSearchDropdown();
    }
  };

  useEffect(() => {
    dispatch(getNavigation());

    document.addEventListener('keydown', handleDropdownKeyDownEvent);

    return () => {
      document.addEventListener('keydown', handleDropdownKeyDownEvent);
    };
  }, []);

  const search = useSelector(selectSearch);
  const iris = useSelector(selectIris);

  const notifications = useSelector(selectNotifications);
  const help = useSelector(selectHelp);
  const account = getAccountList(useSelector(selectAccount), isDesktopScreen);

  const handleDropdownOptionKeyDown = (
    e:
      | React.KeyboardEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLAnchorElement>,
  ) => {
    if (!e.currentTarget || !e.key) {
      return;
    } else if (e.currentTarget.previousElementSibling && e.key === 'ArrowUp') {
      (e.currentTarget.previousElementSibling as HTMLAnchorElement).focus();
    } else if (e.currentTarget.nextElementSibling && e.key === 'ArrowDown') {
      (e.currentTarget.nextElementSibling as HTMLAnchorElement).focus();
    }
  };

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location.pathname]);

  return (
    <header className="header">
      <div className="header-branding">
        <img
          className="header-branding__main-branding"
          src={isDesktopScreen ? SkillsLogo : MobileSkillsLogo}
          alt=""
          role="presentation"
        />
      </div>
      <div className="main-header">
        <Show when={!isMobileScreen}>
          <Search iris={iris} search={search} />
        </Show>

        <div className="main-header__options">
          <Show when={isDesktopScreen && Boolean(notifications.length)}>
            <Link
              to={notifications[0]?.url}
              tabIndex={0}
              aria-label="notifications"
            >
              <div className="main-header__option">
                <BellIcon
                  className="options-dropdown-icon"
                  aria-hidden="true"
                />
                <span className="options-dropdown-span">
                  {t('header.notifications')}
                </span>
              </div>
            </Link>
          </Show>
          <Show when={isMobileScreen}>
            <OptionsDropdown
              fullWidth
              id="options-dropdown-search"
              ref={searchDropdownRef}
              button={
                <>
                  {isSearchDropdownOpen ? (
                    <CloseIcon
                      className="options-dropdown-icon"
                      aria-hidden="true"
                    />
                  ) : (
                    <SearchIcon
                      className="options-dropdown-icon"
                      aria-hidden="true"
                    />
                  )}
                  <span className="options-dropdown-span">
                    {t('header.search')}
                  </span>
                </>
              }
              isOpen={isSearchDropdownOpen}
              options={<Search iris={iris} search={search} />}
              setIsOpen={() => {
                setIsSearchDropdownOpen(!isSearchDropdownOpen);
              }}
            />
          </Show>

          <Show when={isDesktopScreen}>
            <OptionsDropdown
              id="options-dropdown-help"
              ref={helpDropdownRef}
              button={
                <>
                  {isHelpDropdownOpen ? (
                    <CloseIcon
                      className="options-dropdown-icon"
                      aria-hidden="true"
                    />
                  ) : (
                    <HelpCircleIcon
                      className="options-dropdown-icon"
                      aria-hidden="true"
                    />
                  )}
                  <span className="options-dropdown-span">
                    {t('header.help')}
                  </span>
                </>
              }
              isOpen={isHelpDropdownOpen}
              options={
                <div
                  id="account-dropdown-menu"
                  className="dropdown-menu"
                  role="menu"
                  aria-hidden={!isHelpDropdownOpen}
                  tabIndex={-1}
                >
                  <For
                    each={help}
                    children={(helpItem, index) => {
                      return (
                        <Link
                          to={helpItem.url}
                          className="dropdown-option dropdown-option-lg"
                          role="menuitem"
                          onClick={handleCloseHelpDropdown}
                          onKeyDown={handleDropdownOptionKeyDown}
                          tabIndex={0}
                          ref={index === 0 ? helpOptionRef : null}
                        >
                          {ITEM_TO_ICON_MAP[helpItem.id] || (
                            <ExternalLinkIcon aria-hidden="true" />
                          )}
                          <span className="dropdown-option-span">
                            {helpItem.label}
                          </span>
                        </Link>
                      );
                    }}
                  />
                </div>
              }
              setIsOpen={() => {
                setIsHelpDropdownOpen(!isHelpDropdownOpen);
              }}
            />
          </Show>

          <OptionsDropdown
            id="options-dropdown-account"
            ref={accountDropdownRef}
            button={
              <>
                {isAccountDropdownOpen ? (
                  <CloseIcon
                    className="options-dropdown-icon"
                    aria-hidden="true"
                  />
                ) : (
                  <PersonIcon
                    className="options-dropdown-icon"
                    aria-hidden="true"
                  />
                )}
                <span className="options-dropdown-span">
                  {t('header.account')}
                </span>
              </>
            }
            isOpen={isAccountDropdownOpen}
            options={
              <div
                id="account-dropdown-menu"
                className="dropdown-menu"
                role="menu"
                aria-hidden={!isAccountDropdownOpen}
                tabIndex={-1}
              >
                <For
                  each={account}
                  children={(accountItem, index) => {
                    return (
                      <>
                        <Link
                          to={accountItem.url}
                          className={cs('dropdown-option', {
                            'dropdown-option-w-divider': shouldShowDivider(
                              isDesktopScreen,
                              index,
                            ),
                          })}
                          role="menuitem"
                          onClick={handleClickOnAccountLink}
                          onKeyDown={handleDropdownOptionKeyDown}
                          ref={index === 0 ? accountOptionRef : null}
                        >
                          {ITEM_TO_ICON_MAP[accountItem.id] || (
                            <MinusIcon aria-hidden="true" />
                          )}
                          <span className="dropdown-option-span">
                            {accountItem.label}
                          </span>
                        </Link>
                      </>
                    );
                  }}
                />
                <div
                  className="dropdown-option"
                  role="menuitem"
                  tabIndex={0}
                  onClick={handleLogout}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleLogout();
                    } else {
                      handleDropdownOptionKeyDown(e);
                    }
                  }}
                  aria-label={t('header.signOut')}
                >
                  <SignoutIcon color="#FF1675" aria-hidden="true" />
                  <span className="dropdown-option-span">
                    {t('header.signOut')}
                  </span>
                </div>
              </div>
            }
            setIsOpen={() => {
              setIsAccountDropdownOpen(!isAccountDropdownOpen);
            }}
            fullWidth={isMobileScreen}
            backdrop={isMobileScreen}
          />

          <Show when={!isDesktopScreen}>
            <Drawer
              onToggle={() => setIsMenuOpen(!isMenuOpen)}
              id=""
              button={
                <>
                  <Show
                    when={!isMenuOpen}
                    fallback={
                      <CloseIcon
                        className="options-drawer-icon"
                        aria-hidden="true"
                      />
                    }
                  >
                    <MenuIcon
                      className="options-drawer-icon"
                      aria-hidden="true"
                    />
                  </Show>
                  <span className="options-drawer-span">
                    {t('header.menu')}
                  </span>
                </>
              }
              isOpen={isMenuOpen}
            >
              <Navigation />
            </Drawer>
          </Show>
        </div>
      </div>
    </header>
  );
};

export default MainHeader;
