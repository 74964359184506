import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enLang from './locales/en.json';
import esLang from './locales/es.json';
import frLang from './locales/fr.json';
import geLang from './locales/ge.json';
import jpLang from './locales/jp.json';
import ptLang from './locales/pt.json';

const resources = {
  en: {
    translation: enLang,
  },
  fr: {
    translation: frLang,
  },
  es: {
    translation: esLang,
  },
  ge: {
    translation: geLang,
  },
  jp: {
    translation: jpLang,
  },
  pt: {
    translation: ptLang,
  },
};

void i18n.use(initReactI18next).init({
  lng: sessionStorage.getItem('languageNGP') || 'en',
  resources,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
