import { ChevronDownIcon, ChevronUpIcon } from '@pluralsight/icons';
import { For, IconButton, Show } from '@pluralsight/react-ng';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ENavigationOption } from '../../../../shared/interfaces';
import { mapLanguageToNavigationTranslation } from '../../../../translations/utils';
import { selectNavigation, selectRoleSwitcher } from '../../state/selectors';
import { getNavigation } from '../../state/slice';
import NavigationItemComponent from '../navigation-item/navigation-item';
import PlanSwitch from '../plan-switch/plan-switch';
import RoleSwitch from '../role-switch/role-switch';

import type { IAdaptiveNavigationNode } from '../../state/interfaces';
import type { Language } from '../../../../translations/interfaces';
import './navigation.scss';

const Navigation = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language as Language;

  const dispatch = useDispatch();

  const [isLearningMenuOpen, setIsLearningMenuOpen] = useState(true);
  const [isDomainMenuOpen, setIsDomainMenuOpen] = useState(true);
  const [announcement, setAnnouncement] = useState('');
  const [navigationSettings, setNavigationSettings] = useState(
    sessionStorage.getItem('navigationSettings') || ENavigationOption.LEARNER,
  );

  const subscribeToNavigationSettingsChange = () => {
    if ((window as any).globalEventBus) {
      const unsubscribe = (window as any).globalEventBus.subscribe(
        'navigationSettingsChange',
        (update: string) => {
          setNavigationSettings(update);
        },
      );

      return unsubscribe;
    } else {
      console.error('Global Event Bus is not defined.');

      return null;
    }
  };

  useEffect(() => {
    dispatch(getNavigation());

    const unsubscribe = subscribeToNavigationSettingsChange();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const roleSwitcherItems = useSelector(selectRoleSwitcher);
  const navigationItems = useSelector(selectNavigation).sort(
    (one, two) => one.order - two.order,
  );

  const navigation: IAdaptiveNavigationNode[] = navigationItems.filter(
    (navigationItem: IAdaptiveNavigationNode) =>
      navigationItem.navigation.includes(navigationSettings),
  );

  const enableLeaderNavigation = !!roleSwitcherItems.find(
    (item) => item.id === 'role-switcher-leader-navigation',
  );

  const toggleAnnouncement = (prev: boolean) => {
    const newState = !prev;

    setAnnouncement(newState ? 'expanded' : 'collapsed');

    return newState;
  };

  useEffect(() => {
    if (enableLeaderNavigation)
      setNavigationSettings(
        sessionStorage.getItem('navigationSettings') ||
          ENavigationOption.LEADER,
      );
  }, [enableLeaderNavigation]);

  return (
    <nav className="navigation" aria-label="side">
      <RoleSwitch enableLeaderNavigation={enableLeaderNavigation} />
      <PlanSwitch />
      <For
        each={navigation.filter(
          (navigationItem) => navigationItem.group === 'main',
        )}
        children={(navigationItem) => {
          return (
            <NavigationItemComponent
              id={navigationItem.id}
              name={mapLanguageToNavigationTranslation(
                navigationItem.label,
                currentLanguage,
              )}
              path={navigationItem.url}
              tabIndex={0}
              key={navigationItem.id}
            />
          );
        }}
      />
      <Show
        when={
          !!navigation.length && navigationSettings === ENavigationOption.LEADER
        }
      >
        <hr className="navigation__divider" />
        <div className="navigation__learning-header">
          Learning
          <IconButton
            palette="action"
            usage="text"
            size="sm"
            role="button"
            aria-expanded={isLearningMenuOpen}
            ariaLabel={t('learningMenu')}
            onClick={() =>
              setIsLearningMenuOpen((prev) => toggleAnnouncement(prev))
            }
          >
            {isLearningMenuOpen ? (
              <ChevronUpIcon aria-hidden="true" />
            ) : (
              <ChevronDownIcon aria-hidden="true" />
            )}
          </IconButton>
          <div aria-live="assertive" className="sr-only">
            {t(announcement)}
          </div>
        </div>
      </Show>
      <Show
        when={
          !!navigation.length &&
          (isLearningMenuOpen ||
            navigationSettings === ENavigationOption.LEARNER)
        }
      >
        <For
          each={navigation.filter((navigationItem) =>
            navigationItem.group.includes('learning'),
          )}
          children={(navigationItem) => {
            const isBrowse = navigationItem.group.includes('learning-browse');
            const isDomainPage =
              navigationItem.group.includes('learning-domain');

            return (
              <Show when={!isDomainPage || isDomainMenuOpen}>
                <div
                  className={`navigation__item-container ${navigationItem.group}`}
                >
                  <NavigationItemComponent
                    id={navigationItem.id}
                    name={mapLanguageToNavigationTranslation(
                      navigationItem.label,
                      currentLanguage,
                    )}
                    path={navigationItem.url}
                    tabIndex={0}
                    key={navigationItem.id}
                  />
                  <Show
                    when={
                      isBrowse &&
                      navigationSettings === ENavigationOption.LEARNER
                    }
                  >
                    <IconButton
                      className="navigation__icon"
                      palette="action"
                      usage="text"
                      size="sm"
                      role="button"
                      aria-expanded={isDomainMenuOpen}
                      ariaLabel={t('learningMenu')}
                      onClick={() =>
                        setIsDomainMenuOpen((prev) => toggleAnnouncement(prev))
                      }
                    >
                      {isDomainMenuOpen ? (
                        <ChevronUpIcon aria-hidden="true" />
                      ) : (
                        <ChevronDownIcon aria-hidden="true" />
                      )}
                    </IconButton>
                    <div aria-live="assertive" className="sr-only">
                      {t(announcement)}
                    </div>
                  </Show>
                </div>
              </Show>
            );
          }}
        />
      </Show>
    </nav>
  );
};

export default Navigation;
