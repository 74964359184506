import { CONFIGURATION } from '../../../config';
import {
  getPreservedValues,
  LOCAL_WHITELIST,
  restoreValues,
  SESSION_WHITELIST,
} from '../../../shared/storage-helper/storage-helper';

interface ReturnAppLogoutProps {
  handleLogout: () => void;
}

const { LOGOUT } = CONFIGURATION.COMMON.API;

const useAppLogout = (): ReturnAppLogoutProps => {
  const handleLogout = () => {
    try {
      const preservedSession = getPreservedValues(
        sessionStorage,
        SESSION_WHITELIST,
      );
      const preservedLocal = getPreservedValues(localStorage, LOCAL_WHITELIST);

      sessionStorage.clear();
      localStorage.clear();

      restoreValues(sessionStorage, preservedSession);
      restoreValues(localStorage, preservedLocal);

      window.location.href = `${LOGOUT.BASE_URL}${LOGOUT.PATH}`;
    } catch (err) {
      console.error('Error logout current user:', err);
    }
  };

  return {
    handleLogout,
  };
};

export default useAppLogout;
