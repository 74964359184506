import type { IAdaptiveNavigationNode } from '../../../navigation/state/interfaces';

export const getAccountList = (
  items: IAdaptiveNavigationNode[],
  isDesktop: boolean,
): IAdaptiveNavigationNode[] => {
  let result = [...items];

  if (!isDesktop) {
    result = result.concat([
      {
        id: 'notifications',
        order: 1,
        label: 'Notifications',
        url: 'https://app.pluralsight.com/communications/notifications',
      },
      {
        id: 'help-center',
        order: 11,
        label: 'Help',
        url: 'https://help.pluralsight.com/',
      },
    ] as IAdaptiveNavigationNode[]);
  }

  return result.sort((a, b) => a.order - b.order);
};

export const shouldShowDivider = (
  isDesktopScreen: boolean,
  index: number,
): boolean => {
  return isDesktopScreen
    ? index === 1 || index === 4
    : index === 3 || index === 6;
};
