import axios from 'axios';

import type {
  AxiosInstance,
  InternalAxiosRequestConfig,
  AxiosError,
} from 'axios';

export const createHttpClient = (): AxiosInstance => {
  const httpClient = axios.create();

  httpClient.interceptors.request.use(
    (
      config: InternalAxiosRequestConfig,
    ): InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig> => {
      return { ...config, withCredentials: true };
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  httpClient.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        sessionStorage.removeItem('userLoggedIn');
        window.location.href = '/login';
      }

      return Promise.reject(error);
    },
  );

  return httpClient;
};

const index = createHttpClient();

export default index;
