export const SESSION_WHITELIST = ['languageNGP'];
export const LOCAL_WHITELIST = ['interestsSelected'];

export const getPreservedValues = (
  storage: Storage,
  keys: string[],
): Record<string, string> =>
  keys.reduce(
    (acc, key) => {
      const value = storage.getItem(key);

      if (value !== null) acc[key] = value;

      return acc;
    },
    {} as Record<string, string>,
  );

export const restoreValues = (
  storage: Storage,
  values: Record<string, string>,
): void => {
  Object.entries(values).forEach(([key, value]) => storage.setItem(key, value));
};
