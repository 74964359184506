import { ToastProvider } from '@pluralsight/react-ng';
import './translations';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './components/app/app';
import eventBus from './shared/events';
import store from './store';
import './index.css';

const rootElement = document.getElementById('root');

if (!(window as any).globalEventBus) {
  (window as any).globalEventBus = eventBus;
}

if (rootElement) {
  const root = createRoot(rootElement);

  root.render(
    <Provider store={store}>
      <ToastProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ToastProvider>
    </Provider>,
  );
} else {
  console.error('Root element not found');
}
