import type { USER_ROLE } from '../../../shared/interfaces';

export interface Navigation {
  navigation: NavigationItem[];
}

export interface NavigationItem {
  id: string;
  groupId: number;
  nextgen: boolean;
  title: string;
  url: string;
}

export interface NavigationGroup {
  id: string;
  title: string;
  order: number;
  items: NavigationItem[];
}

export interface NavigationState {
  items: IAdaptiveNavigationNode[];
  error: string | null;
  loading: boolean;
}

export enum IAdaptiveNavigationNodeLocation {
  ACCOUNT = 'account',
  ACCOUNT_PLAN_SWITCHER = 'account-plan-switcher',
  APP_SWITCHER = 'app-switcher',
  HELP = 'help',
  IRIS = 'iris',
  NAVIGATION = 'navigation',
  NOTIFICATIONS = 'notifications',
  ROLE_SWITCHER = 'role-switcher',
  SEARCH = 'search',
  SIGNOUT = 'signout',
  TBD = 'tbd',
  UPGRADE_BUTTON = 'upgrade-button',
}

export interface IAdaptiveNavigationNode {
  readonly id: string;
  readonly flags: Array<string>;
  readonly group: string;
  readonly label: string;
  readonly location: IAdaptiveNavigationNodeLocation;
  readonly name: string;
  readonly navigation: string[];
  readonly nextgen: boolean;
  readonly order: number;
  readonly roles: Array<USER_ROLE>;
  readonly url: string;
}
