import React, {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import './options-dropdown.scss';
import cs from 'classnames';

import Backdrop from '../backdrop/backdrop';

interface HeaderOptionProps {
  id: string;
  button: React.ReactNode;
  isOpen: boolean;
  options: React.ReactNode;
  setIsOpen: () => void;
  fullWidth?: boolean;
  backdrop?: boolean;
}

const OptionsDropdown = forwardRef<HTMLDivElement, HeaderOptionProps>(
  ({ id, button, isOpen, options, setIsOpen, fullWidth, backdrop }, ref) => {
    const internalRef = useRef<HTMLDivElement | null>(null);
    const [rightOffset, setRightOffset] = useState(0);

    const handleForwardRef = (value: HTMLDivElement | null) => {
      internalRef.current = value;

      if (!ref) return;

      if (typeof ref === 'function') {
        ref(value);
      } else {
        (ref as React.MutableRefObject<HTMLDivElement | null>).current = value;
      }
    };

    useEffect(() => {
      if (!isOpen) return;

      const handleClickOutside = (event: MouseEvent) => {
        if (
          internalRef.current &&
          !internalRef.current.contains(event.target as Node)
        ) {
          setIsOpen();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () =>
        document.removeEventListener('mousedown', handleClickOutside);
    }, [isOpen, setIsOpen]);

    useLayoutEffect(() => {
      if (isOpen && fullWidth && internalRef.current) {
        const { right } = internalRef.current.getBoundingClientRect();

        setRightOffset(window.innerWidth - right);
      }
    }, [isOpen, fullWidth]);

    return (
      <div
        ref={handleForwardRef}
        className={cs('options-dropdown', { open: isOpen })}
      >
        {isOpen && backdrop ? <Backdrop onClick={setIsOpen} /> : null}

        <button
          id={id}
          aria-haspopup="true"
          aria-expanded={isOpen}
          className="options-dropdown-button"
          onClick={setIsOpen}
          onKeyDown={(e) => e.key === 'Enter' && setIsOpen()}
        >
          {button}
        </button>

        <div
          className={cs('options-dropdown-pane', { 'full-width': fullWidth })}
          style={fullWidth ? { right: `-${rightOffset}px` } : undefined}
        >
          {options}
        </div>
      </div>
    );
  },
);

export default OptionsDropdown;
