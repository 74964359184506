import { useIdleTimer } from 'react-idle-timer';
import Cookies from 'js-cookie';

import useAppLogout from '../../features/header/hooks/useAppLogout';

const inactivityWindowTime = JSON.parse(
  Cookies.get('Inactivity') || '{"inactivityWindowTime": 10800}', // default time is 3 hours if no cookies
).inactivityWindowTime;

const IdleHandler = () => {
  const { handleLogout } = useAppLogout();

  const onIdle = () => {
    handleLogout();
  };

  useIdleTimer({
    onIdle,
    timeout: inactivityWindowTime * 1000,
    throttle: 500,
  });

  return null;
};

export default IdleHandler;
