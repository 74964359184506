import { CONFIGURATION } from '../config';

import type { Language, LanguageOption } from './interfaces';

const { LANGUAGE_OPTIONS } = CONFIGURATION.COMMON.LANGUAGES;

export const LANGUAGES: LanguageOption[] = LANGUAGE_OPTIONS.map(
  (OPTION: { LABEL: string; VALUE: Language }) => ({
    label: OPTION.LABEL,
    value: OPTION.VALUE,
  }),
);

export const NAVIGATION_TRANSLATIONS: Record<
  Language,
  Record<string, string>
> = {
  en: {},
  es: {
    Home: 'Inicio',
    'Leader Hub': 'Centro de líderes',
    People: 'Personas',
    Account: 'Cuenta',
    'Account Settings': 'Configuración de la cuenta',
    Priorities: 'Prioridades',
    Sandboxes: 'Máquinas virtuales',
    Tech: 'Tecnología',
    'Browse All': 'Explorar todo',
    Teams: 'Equipos',
    Channels: 'Canales',
    Paths: 'Rutas',
    Certifications: 'Certificaciones',
    'Skill IQ': 'Coeficiente IQ',
    Notifications: 'Notificaciones',
    SSO: 'SSO',
  },
  pt: {
    Home: 'Início',
    'Leader Hub': 'Centro de líderes',
    People: 'Pessoas',
    Account: 'Conta',
    'Account Settings': 'Configurações da conta',
    Priorities: 'Prioridades',
    Sandboxes: 'Caixas de areia',
    Tech: 'Tecnologia',
    'Browse All': 'Explorar tudo',
    Teams: 'Equipes',
    Channels: 'Canais',
    Paths: 'Caminhos',
    Certifications: 'Certificações',
    'Skill IQ': 'QI de habilidades',
    Notifications: 'Notificações',
    SSO: 'SSO',
  },
  jp: {
    Home: 'ホーム',
    'Leader Hub': 'リーダーハブ',
    People: '人々',
    Account: 'アカウント',
    'Account Settings': 'アカウント設定',
    Priorities: '優先事項',
    Sandboxes: 'サンドボックス',
    Tech: '技術',
    'Browse All': 'すべて閲覧',
    Teams: 'チーム',
    Channels: 'チャンネル',
    Paths: 'パス',
    Certifications: '認証',
    'Skill IQ': 'スキルIQ',
    Notifications: '通知',
    SSO: 'シングルサインオン',
  },
  ge: {
    Home: 'Startseite',
    'Leader Hub': 'Führungszentrum',
    People: 'Personen',
    Account: 'Konto',
    'Account Settings': 'Konteneinstellungen',
    Priorities: 'Prioritäten',
    Sandboxes: 'Sandkästen',
    Tech: 'Technik',
    'Browse All': 'Alles durchsuchen',
    Teams: 'Teams',
    Channels: 'Kanäle',
    Paths: 'Wege',
    Certifications: 'Zertifizierungen',
    'Skill IQ': 'Fähigkeit IQ',
    Notifications: 'Benachrichtigungen',
    SSO: 'SSO',
  },
  fr: {
    Home: 'Accueil',
    'Leader Hub': 'Centre des leaders',
    People: 'Personnes',
    Account: 'Compte',
    'Account Settings': 'Paramètres du compte',
    Priorities: 'Priorités',
    Sandboxes: 'Bacs à sable',
    Tech: 'Technologie',
    'Browse All': 'Parcourir tout',
    Teams: 'Équipes',
    Channels: 'Canaux',
    Paths: 'Chemins',
    Certifications: 'Certifications',
    'Skill IQ': 'QI de compétences',
    Notifications: 'Notifications',
    SSO: 'SSO',
  },
};
