import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlexContainer } from '@pluralsight/react-ng';
import './main-content-link.scss';

interface MainContentLinkProps {
  handleFocus: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

const MainContentLink: React.FC<MainContentLinkProps> = ({ handleFocus }) => {
  const { t } = useTranslation();

  return (
    <FlexContainer className="main-content-focus">
      <a
        href="#plan-management-container"
        onClick={handleFocus}
        className="main-content-focus__link"
      >
        {t('mainContentLink')}
      </a>
    </FlexContainer>
  );
};

export default MainContentLink;
