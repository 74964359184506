import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { IAdaptiveNavigationNode, NavigationState } from './interfaces';

const INITIAL_STATE: NavigationState = {
  items: [],
  error: null,
  loading: false,
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: INITIAL_STATE,
  reducers: {
    getNavigation: (state) => {
      state.error = null;
      state.loading = true;
    },
    getNavigationSuccess: (state) => {
      state.loading = false;
    },
    getNavigationFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetNavigation: (state) => {
      state.items = [];
    },
    setNavigation: (
      state,
      action: PayloadAction<IAdaptiveNavigationNode[]>,
    ) => {
      state.items = action.payload;
    },
  },
});

export const {
  getNavigation,
  getNavigationSuccess,
  getNavigationFailure,
  setNavigation,
} = navigationSlice.actions;

export default navigationSlice.reducer;
