import { Input, IrisDefaultIcon } from '@pluralsight/react';
import { FormControlProvider } from '@pluralsight/react-ng';
import React from 'react';

import type { IAdaptiveNavigationNode } from '../../../navigation/state/interfaces';

interface SearchProps {
  search: IAdaptiveNavigationNode[];
  iris: IAdaptiveNavigationNode[];
}

const Search = ({ iris, search }: SearchProps): JSX.Element => {
  return (
    <div className="main-header__search-and-iris">
      {Boolean(search.length) && (
        <FormControlProvider>
          <Input id="search" size="md" name="search" placeholder="" disabled />
        </FormControlProvider>
      )}
      {Boolean(iris.length) && (
        <button
          className="main-header__iris-button"
          tabIndex={0}
          aria-label="IRIS"
          disabled
        >
          <IrisDefaultIcon
            className="main-header__iris-icon"
            aria-hidden="true"
          />
          <span>Iris</span>
        </button>
      )}
    </div>
  );
};

export default Search;
