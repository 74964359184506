// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.role-switch__container {
  position: relative;
}
.role-switch__button {
  align-items: center;
  background-color: var(--pando-colors-action-bg-100-initial);
  cursor: pointer;
  display: flex;
  height: var(--pando-spacing-10);
  justify-content: space-between;
  margin-bottom: var(--pando-spacing-2);
  margin-top: var(--pando-spacing-6);
  padding: 0 var(--pando-spacing-6);
}
.role-switch__chevron-down-icon {
  height: var(--pando-spacing-6);
  width: var(--pando-spacing-6);
}
.role-switch__dropdown-container {
  background-color: var(--pando-colors-neutral-surface-100);
  border: 1px solid var(----pando-colors-neutral-border-initial);
  border-radius: var(--pando-spacing-2);
  left: 50%;
  padding: var(--pando-spacing-1) var(--pando-spacing-2);
  position: absolute;
  top: var(--pando-spacing-12);
  transform: translateX(-50%);
  width: calc(100% - 2 * var(--pando-spacing-2));
  z-index: 1;
}
.role-switch__dropdown-option {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: var(--pando-spacing-10);
  justify-content: space-between;
  padding: 0 var(--pando-spacing-2);
}`, "",{"version":3,"sources":["webpack://./src/features/navigation/components/role-switch/role-switch.scss"],"names":[],"mappings":"AAEE;EACE,kBAAA;AADJ;AAIE;EACE,mBAAA;EACA,2DAAA;EACA,eAAA;EACA,aAAA;EACA,+BAAA;EACA,8BAAA;EACA,qCAAA;EACA,kCAAA;EACA,iCAAA;AAFJ;AAKE;EACE,8BAAA;EACA,6BAAA;AAHJ;AAME;EACE,yDAAA;EACA,8DAAA;EACA,qCAAA;EACA,SAAA;EACA,sDAAA;EACA,kBAAA;EACA,4BAAA;EACA,2BAAA;EACA,8CAAA;EACA,UAAA;AAJJ;AAOE;EACE,mBAAA;EACA,eAAA;EACA,aAAA;EACA,+BAAA;EACA,8BAAA;EACA,iCAAA;AALJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.role-switch {\n  &__container {\n    position: relative;\n  }\n\n  &__button {\n    align-items: center;\n    background-color: var(--pando-colors-action-bg-100-initial);\n    cursor: pointer;\n    display: flex;\n    height: var(--pando-spacing-10);\n    justify-content: space-between;\n    margin-bottom: var(--pando-spacing-2);\n    margin-top: var(--pando-spacing-6);\n    padding: 0 var(--pando-spacing-6);\n  }\n\n  &__chevron-down-icon {\n    height: var(--pando-spacing-6);\n    width: var(--pando-spacing-6);\n  }\n\n  &__dropdown-container {\n    background-color: var(--pando-colors-neutral-surface-100);\n    border: 1px solid var(----pando-colors-neutral-border-initial);\n    border-radius: var(--pando-spacing-2);\n    left: 50%;\n    padding: var(--pando-spacing-1) var(--pando-spacing-2);\n    position: absolute;\n    top: var(--pando-spacing-12);\n    transform: translateX(-50%);\n    width: calc(100% - 2 * var(--pando-spacing-2));\n    z-index: 1;\n  }\n\n  &__dropdown-option {\n    align-items: center;\n    cursor: pointer;\n    display: flex;\n    height: var(--pando-spacing-10);\n    justify-content: space-between;\n    padding: 0 var(--pando-spacing-2);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
