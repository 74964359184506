// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-content-focus {
  background-color: var(--pando-inky-blue-neutral-surface-100-dark);
  position: absolute;
  top: -42px;
  transition: top 0.3s;
  width: auto;
  z-index: var(--pando-z-index-dropdown);
}
.main-content-focus__link {
  border: 1px solid var(--pando-inky-blue-action-navigation-initial-dark);
  border-radius: var(--pando-radii-md);
  color: var(--pando-inky-blue-action-navigation-initial-dark);
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: var(--pando-font-weights-medium);
  line-height: 150%;
  margin: var(--pando-spacing-2);
  padding: 4px;
  text-decoration: none;
}
.main-content-focus:focus-within {
  top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/main-content-link/main-content-link.scss"],"names":[],"mappings":"AACA;EACE,iEAAA;EACA,kBAAA;EACA,UAAA;EACA,oBAAA;EACA,WAAA;EACA,sCAAA;AAAF;AAEE;EACE,uEAAA;EACA,oCAAA;EACA,4DAAA;EACA,8BAAA;EACA,kBAAA;EACA,6CAAA;EACA,iBAAA;EACA,8BAAA;EACA,YAAA;EACA,qBAAA;AAAJ;AAGE;EACE,SAAA;AADJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.main-content-focus {\n  background-color: var(--pando-inky-blue-neutral-surface-100-dark);\n  position: absolute;\n  top: -42px;\n  transition: top 0.3s;\n  width: auto;\n  z-index: var(--pando-z-index-dropdown);\n\n  &__link {\n    border: 1px solid var(--pando-inky-blue-action-navigation-initial-dark);\n    border-radius: var(--pando-radii-md);\n    color: var(--pando-inky-blue-action-navigation-initial-dark);\n    font-size: var(--font-size-sm);\n    font-style: normal;\n    font-weight: var(--pando-font-weights-medium);\n    line-height: 150%;\n    margin: var(--pando-spacing-2);\n    padding: 4px;\n    text-decoration: none;\n  }\n\n  &:focus-within {\n    top: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
