/* eslint-disable @nx/enforce-module-boundaries */
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { registerApplication, start } from 'single-spa';
import { PandoBreakpointsLg } from '@pluralsight/design-tokens';
import { useMediaQuery } from 'react-responsive';
import { Show } from '@pluralsight/react-ng';

import { CONFIGURATION } from '../../config';
import Footer from '../../features/footer/components/footer/footer';
import { setAuthentication, setUser } from '../../features/global/state/slice';
import MainHeader from '../../features/header/components/main-header/main-header';
import Navigation from '../../features/navigation/components/navigation/navigation';
import * as isActive from '../../shared/activities';
import index from '../../shared/http';
import { sagaMiddleware } from '../../store';
import MainContentLink from '../main-content-link/main-content-link';
import IdleHandler from '../idle-handler/idle-handler';

import type { AppLifeCycles } from '../../shared/interfaces';
import type { AppDispatch, RootState } from '../../store';
import './app.scss';

interface TenantDetails {
  AUTH0_AUDIENCE: string;
  AUTH0_DOMAIN: string;
  AUTH0_CLIENT_ID: string;
  DISPLAY_NAME: string;
  REGION: string;
  UI_BASE_URL: string;
}

interface Tenants {
  [key: string]: { [key: string]: TenantDetails };
}

const { PROVISIONED_REGION, TENANTS, ROUTES } = CONFIGURATION.COMMON;

// TODO (przem-wierzbicki): RE-ORGANIZE SIDE EFFECT MANAGEMENT
const App: React.FC = () => {
  const { i18n } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const mainContentRef = useRef<HTMLDivElement>(null);
  const isDesktopScreen = useMediaQuery({
    query: `(min-width: ${PandoBreakpointsLg}`,
  });

  const [activeApp, setActiveApp] = useState<string | null>(null);

  const user = useSelector((state: RootState) => state.global.user);
  const isAuthenticated = useSelector(
    (state: RootState) => state.global.isAuthenticated,
  );

  const subscribeToLanguageChange = () => {
    if ((window as any).globalEventBus) {
      const unsubscribe = (window as any).globalEventBus.subscribe(
        'languageChange',
        (newLanguage: string) => {
          if (newLanguage && i18n.language !== newLanguage) {
            i18n
              .changeLanguage(newLanguage)
              .catch((err) => console.error('Error changing language:', err));
          }
        },
      );

      return unsubscribe;
    } else {
      console.error('Global Event Bus is not defined.');

      return null;
    }
  };

  useEffect(() => {
    const unsubscribe = subscribeToLanguageChange();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const validateLoggedUser = () => {
    const userLoggedIn = sessionStorage.getItem('userLoggedIn');
    const redirectToUrl = localStorage.getItem('redirectToUrl');
    const currentPath = location.pathname;

    if (userLoggedIn) {
      dispatch(setAuthentication(true));

      if (redirectToUrl) {
        navigate(redirectToUrl);
        localStorage.removeItem('redirectToUrl');
      }
    } else if (currentPath !== '/login') {
      localStorage.setItem('redirectToUrl', currentPath);
      dispatch(setAuthentication(false));
      navigate('/login');
    }
  };

  useEffect(() => {
    validateLoggedUser();
    (window as any).globalEventBus.subscribe('authChange', validateLoggedUser);
  }, []);

  useEffect(() => {
    sagaMiddleware.setContext({ httpClient: index });
  }, []);

  useEffect(() => {
    if (!isAuthenticated) return;

    const interestsSelected = localStorage.getItem('interestsSelected');

    if (!interestsSelected && location.pathname !== ROUTES.INTERESTS) {
      navigate(ROUTES.INTERESTS);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      registerApplication({
        name: '@ps-nextgen/common-pages',
        app: () =>
          import(
            '@ps-nextgen/common-pages'
          ) as unknown as Promise<AppLifeCycles>,
        activeWhen: (location) =>
          isActive.commonPages(location, isAuthenticated),
        customProps: {
          domElement: document.getElementById('common-pages-container')!,
          httpClient: index,
        },
      });

      registerApplication({
        name: '@ps-nextgen/plan-management',
        app: () =>
          import(
            '@ps-nextgen/plan-management'
          ) as unknown as Promise<AppLifeCycles>,
        activeWhen: (location) => {
          const active: boolean = isActive.planManagement(
            location,
            isAuthenticated,
          );

          if (active) setActiveApp('plan-management');

          return active;
        },
        customProps: {
          domElement: document.getElementById('plan-management-container')!,
          httpClient: index,
        },
      });

      const userLogged = JSON.parse(
        sessionStorage.getItem('userLoggedIn') || '{}',
      );

      if (userLogged) {
        const { email, appMetadata } = userLogged;
        const { psId, terms_accepted } = appMetadata;

        if (psId && !terms_accepted) {
          navigate(`/create-account?id=${psId}&email=${email}`);

          return;
        }
      }
    } else {
      const data: { tenants: Tenants } = { tenants: TENANTS };
      let provisionedRegion = Cookies.get('provisionedRegion');
      let accountType = Cookies.get('accountType');

      if (!provisionedRegion) {
        provisionedRegion = PROVISIONED_REGION as string;
        accountType = 'B2C';
        Cookies.set('accountType', accountType, { expires: 1 });
        Cookies.set('provisionedRegion', provisionedRegion, { expires: 1 });

        const selectedTenant = data.tenants[accountType][provisionedRegion];

        if (!selectedTenant) {
          console.error('No tenant configuration found for:', accountType);

          return;
        }

        Cookies.set('tenantDetails', JSON.stringify(selectedTenant), {
          expires: 1,
        });
        // eslint-disable-next-line no-console
        console.log('Provisioned region set to default:', provisionedRegion);
      }

      registerApplication({
        name: '@ps-nextgen/authentication',
        app: () =>
          import(
            '@ps-nextgen/authentication'
          ) as unknown as Promise<AppLifeCycles>,
        activeWhen: (location) => {
          const active: boolean = isActive.authentication(
            location,
            isAuthenticated,
          );

          if (active) setActiveApp('authentication');

          return active;
        },
        customProps: {
          domElement: document.getElementById('authentication-container')!,
          httpClient: index,
        },
      });
    }

    start(); // Start Single-SPA applications
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    const handleUserLoggedIn = (userData: any) => {
      dispatch(setUser(userData.user));
      (window as any).globalEventBus.publish('userFetched', userData.user);
    };

    (window as any).globalEventBus.subscribe('authChanged', handleUserLoggedIn);

    const handleGetUser = () => {
      (window as any).globalEventBus.publish('userFetched', user);
    };

    (window as any).globalEventBus.subscribe('getUser', handleGetUser);
  }, [dispatch, user]);

  const handleFocus = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    mainContentRef.current?.focus();
  };

  return (
    <>
      <div id="authentication-container" className="authentication-container" />
      {isAuthenticated && activeApp !== 'authentication' && (
        <div className="app-container">
          <MainContentLink handleFocus={handleFocus} />
          <MainHeader />
          <Show when={isDesktopScreen}>
            <Navigation />
          </Show>
          <IdleHandler />

          <div className="app-container__main content">
            <div id="backdrop" />
            <div id="drawer" />
            <main>
              <div
                id="plan-management-container"
                ref={mainContentRef}
                tabIndex={-1}
              />
              <div id="common-pages-container" />
            </main>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default App;
