import { CONFIGURATION } from '../../config';

const {
  CREATE_ACCOUNT,
  HOME,
  LOGIN,
  NOTIFICATIONS,
  PEOPLE,
  PLANS,
  PROFILE,
  SETTINGS,
  ACCOUNT_SETTINGS,
  TEAMS,
  SSO_CONFIGURATION,
  INTERESTS,
  ISSUE,
} = CONFIGURATION.COMMON.ROUTES;

export const authentication = (
  location: Location,
  isAuthenticated: boolean,
) => {
  const SUPPORTED_LOCATIONS = [CREATE_ACCOUNT, PLANS, INTERESTS, ISSUE];

  for (const supportedLocation of SUPPORTED_LOCATIONS) {
    if (location.pathname.includes(supportedLocation)) return true;
  }

  return !isAuthenticated && location.pathname === LOGIN;
};

export const commonPages = (location: Location, isAuthenticated: boolean) => {
  if (!isAuthenticated) return false;

  const SUPPORTED_EXACT_LOCATIONS = [
    NOTIFICATIONS,
    PROFILE,
    SETTINGS,
    ACCOUNT_SETTINGS,
  ];

  for (const supportedLocation of SUPPORTED_EXACT_LOCATIONS) {
    if (location.pathname === supportedLocation) return true;
  }

  return false;
};

export const planManagement = (
  location: Location,
  isAuthenticated: boolean,
) => {
  if (!isAuthenticated) return false;

  const SUPPORTED_EXACT_LOCATIONS = [HOME, PEOPLE, ACCOUNT_SETTINGS];

  for (const supportedLocation of SUPPORTED_EXACT_LOCATIONS) {
    if (location.pathname === supportedLocation) return true;
  }

  const SUPPORTED_LOCATIONS = [TEAMS, SSO_CONFIGURATION];

  for (const supportedLocation of SUPPORTED_LOCATIONS) {
    if (location.pathname.includes(supportedLocation)) return true;
  }

  return false;
};
