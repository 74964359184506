import { NAVIGATION_TRANSLATIONS } from './constants';

import type { Language } from './interfaces';

export const mapLanguageToNavigationTranslation = (
  label: string,
  language: Language,
): string => {
  if (language === 'en') return label;

  return NAVIGATION_TRANSLATIONS[language][label] || 'Not translated';
};
