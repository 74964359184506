import { createSelector } from 'reselect';

import { IAdaptiveNavigationNodeLocation } from './interfaces';

import type { IAdaptiveNavigationNode, NavigationState } from './interfaces';
import type { AppState } from '../../../store';

// TODO (przem-wierzbicki): DEDUPLICATE

const selectNavigationState = (state: AppState) => state.navigation;

export const selectAdaptiveNavigationNodes = createSelector(
  [selectNavigationState],
  (navigationState: NavigationState): IAdaptiveNavigationNode[] =>
    navigationState.items,
);

export const selectAccount = createSelector(
  [selectAdaptiveNavigationNodes],
  (
    adaptiveNavigationNodes: IAdaptiveNavigationNode[],
  ): IAdaptiveNavigationNode[] =>
    adaptiveNavigationNodes.filter(
      (adaptiveNavigationNode: IAdaptiveNavigationNode) =>
        adaptiveNavigationNode.location ===
        IAdaptiveNavigationNodeLocation.ACCOUNT,
    ),
);

export const selectHelp = createSelector(
  [selectAdaptiveNavigationNodes],
  (
    adaptiveNavigationNodes: IAdaptiveNavigationNode[],
  ): IAdaptiveNavigationNode[] =>
    adaptiveNavigationNodes.filter(
      (adaptiveNavigationNode: IAdaptiveNavigationNode) =>
        adaptiveNavigationNode.location ===
        IAdaptiveNavigationNodeLocation.HELP,
    ),
);

export const selectIris = createSelector(
  [selectAdaptiveNavigationNodes],
  (
    adaptiveNavigationNodes: IAdaptiveNavigationNode[],
  ): IAdaptiveNavigationNode[] =>
    adaptiveNavigationNodes.filter(
      (adaptiveNavigationNode: IAdaptiveNavigationNode) =>
        adaptiveNavigationNode.location ===
        IAdaptiveNavigationNodeLocation.IRIS,
    ),
);

export const selectNavigation = createSelector(
  [selectAdaptiveNavigationNodes],
  (
    adaptiveNavigationNodes: IAdaptiveNavigationNode[],
  ): IAdaptiveNavigationNode[] =>
    adaptiveNavigationNodes.filter(
      (adaptiveNavigationNode: IAdaptiveNavigationNode) =>
        adaptiveNavigationNode.location ===
        IAdaptiveNavigationNodeLocation.NAVIGATION,
    ),
);

export const selectNextgenNavigation = createSelector(
  [selectNavigation],
  (navigation: IAdaptiveNavigationNode[]): IAdaptiveNavigationNode[] =>
    navigation.filter(
      (adaptiveNavigationNode: IAdaptiveNavigationNode) =>
        adaptiveNavigationNode.nextgen,
    ),
);

export const selectNotifications = createSelector(
  [selectAdaptiveNavigationNodes],
  (
    adaptiveNavigationNodes: IAdaptiveNavigationNode[],
  ): IAdaptiveNavigationNode[] =>
    adaptiveNavigationNodes.filter(
      (adaptiveNavigationNode: IAdaptiveNavigationNode) =>
        adaptiveNavigationNode.location ===
        IAdaptiveNavigationNodeLocation.NOTIFICATIONS,
    ),
);

export const selectRoleSwitcher = createSelector(
  [selectAdaptiveNavigationNodes],
  (
    adaptiveNavigationNodes: IAdaptiveNavigationNode[],
  ): IAdaptiveNavigationNode[] =>
    adaptiveNavigationNodes.filter(
      (adaptiveNavigationNode: IAdaptiveNavigationNode) =>
        adaptiveNavigationNode.location ===
        IAdaptiveNavigationNodeLocation.ROLE_SWITCHER,
    ),
);

export const selectSearch = createSelector(
  [selectAdaptiveNavigationNodes],
  (
    adaptiveNavigationNodes: IAdaptiveNavigationNode[],
  ): IAdaptiveNavigationNode[] =>
    adaptiveNavigationNodes.filter(
      (adaptiveNavigationNode: IAdaptiveNavigationNode) =>
        adaptiveNavigationNode.location ===
        IAdaptiveNavigationNodeLocation.SEARCH,
    ),
);
