import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import globalReducer from '../features/global/state/slice';
import navigationReducer from '../features/navigation/state/slice';

import rootSaga from './sagas';

import type { NavigationState } from '../features/navigation/state/interfaces';
import type { GlobalState } from '../features/global/state/interface';
import type { AxiosInstance } from 'axios';
import type { EnhancedStore } from '@reduxjs/toolkit';

export const sagaMiddleware = createSagaMiddleware({
  context: {
    httpClient: null as AxiosInstance | null,
  },
});

type TStoreReducers = {
  global: GlobalState;
  navigation: NavigationState;
};

const rootReducerGlobal = combineReducers({
  global: globalReducer,
  navigation: navigationReducer,
});

const store: EnhancedStore<Partial<TStoreReducers>> = configureStore({
  devTools: {
    name: 'Container Store',
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
    }).concat(sagaMiddleware),
  reducer: rootReducerGlobal,
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof rootReducerGlobal>;
export type AppState = ReturnType<typeof store.dispatch>;
export type AppDispatch = typeof store.dispatch;

export default store;

(window as any).globalState = () => {
  return store.getState().global;
};
