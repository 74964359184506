import { For, Show } from '@pluralsight/react-ng';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckIcon, ChevronDownIcon } from '@pluralsight/icons';
import './role-switch.scss';
import { useNavigate } from 'react-router-dom';

import { ENavigationOption } from '../../../../shared/interfaces';

interface RoleSwitchProps {
  enableLeaderNavigation: boolean;
}

const RoleSwitch: React.FC<RoleSwitchProps> = ({ enableLeaderNavigation }) => {
  const ROLE_SWITCH_TO_REDIRECTION_MAP = {
    [ENavigationOption.LEADER]: '/people',
    [ENavigationOption.LEARNER]: '/',
  };

  const roleSwitchOptions: ENavigationOption[] = [
    enableLeaderNavigation && ENavigationOption.LEADER,
    ENavigationOption.LEARNER,
  ].filter((option) => !!option);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [navigationSettings, updateNavigationSettings] = useState(
    sessionStorage.getItem('navigationSettings') || roleSwitchOptions[0],
  );

  useEffect(() => {
    updateNavigationSettings(
      sessionStorage.getItem('navigationSettings') || roleSwitchOptions[0],
    );
  }, [roleSwitchOptions]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleUpdateNavigationSettings = (update: ENavigationOption) => {
    updateNavigationSettings(update);
    sessionStorage.setItem('navigationSettings', update);
    (window as any).globalEventBus.publish('navigationSettingsChange', update);
    setIsDropdownOpen(false);
    navigate(ROLE_SWITCH_TO_REDIRECTION_MAP[update]);
  };

  return (
    <div className="role-switch__container">
      <div className="role-switch__button" onClick={toggleDropdown}>
        {t(`adaptiveNavigation.${navigationSettings}`)}
        <ChevronDownIcon className="role-switch__chevron-down-icon" />
      </div>
      <Show when={isDropdownOpen}>
        <div className="role-switch__dropdown-container">
          <For
            each={roleSwitchOptions}
            children={(option) => {
              return (
                <div
                  className="role-switch__dropdown-option"
                  onClick={() => handleUpdateNavigationSettings(option)}
                >
                  {t(`adaptiveNavigation.${option}`)}
                  <Show when={navigationSettings === option}>
                    <CheckIcon />
                  </Show>
                </div>
              );
            }}
          />
        </div>
      </Show>
    </div>
  );
};

export default RoleSwitch;
