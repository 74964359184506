// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.options-dropdown {
  position: relative;
}
.options-dropdown .options-dropdown-button {
  align-items: center;
  border-radius: var(--pando-radii-md-border-radius);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--pando-spacing-1) var(--pando-spacing-2);
}
.options-dropdown .options-dropdown-button:hover {
  background-color: var(--pando-colors-neutral-border-100);
}
.options-dropdown .options-dropdown-pane {
  background-color: var(--pando-colors-neutral-surface-100);
  border: 1px solid var(--pando-colors-neutral-border-100);
  border-radius: var(--pando-radii-md-border-radius);
  bottom: calc(var(--pando-spacing-2) * -1);
  display: none;
  flex-direction: column;
  position: absolute;
  right: 0;
  transform: translateY(100%);
  min-width: 200px;
  z-index: var(--pando-z-index-modal);
}
.options-dropdown .options-dropdown-pane.full-width {
  width: 100vw;
}
.options-dropdown.open .options-dropdown-button {
  background-color: var(--pando-colors-neutral-border-100);
}
.options-dropdown.open .options-dropdown-pane {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/options-dropdown/options-dropdown.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;AAAF;AAEE;EACE,mBAAA;EACA,kDAAA;EACA,eAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,sDAAA;AAAJ;AAEI;EACE,wDAAA;AAAN;AAIE;EACE,yDAAA;EACA,wDAAA;EACA,kDAAA;EACA,yCAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,QAAA;EACA,2BAAA;EACA,gBAAA;EACA,mCAAA;AAFJ;AAII;EACE,YAAA;AAFN;AAOI;EACE,wDAAA;AALN;AAQI;EACE,aAAA;AANN","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.options-dropdown {\n  position: relative;\n\n  .options-dropdown-button {\n    align-items: center;\n    border-radius: var(--pando-radii-md-border-radius);\n    cursor: pointer;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    padding: var(--pando-spacing-1) var(--pando-spacing-2);\n\n    &:hover {\n      background-color: var(--pando-colors-neutral-border-100);\n    }\n  }\n\n  .options-dropdown-pane {\n    background-color: var(--pando-colors-neutral-surface-100);\n    border: 1px solid var(--pando-colors-neutral-border-100);\n    border-radius: var(--pando-radii-md-border-radius);\n    bottom: calc(var(--pando-spacing-2) * -1);\n    display: none;\n    flex-direction: column;\n    position: absolute;\n    right: 0;\n    transform: translateY(100%);\n    min-width: 200px;\n    z-index: var(--pando-z-index-modal);\n\n    &.full-width {\n      width: 100vw;\n    }\n  }\n\n  &.open {\n    .options-dropdown-button {\n      background-color: var(--pando-colors-neutral-border-100);\n    }\n\n    .options-dropdown-pane {\n      display: flex;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
