import {
  BriefcaseIcon,
  BrowseIcon,
  CertificationIcon,
  ChannelIcon,
  CloudIcon,
  CodeIcon,
  GearIcon,
  HomeIcon,
  LeaderAccountIcon,
  LeaderAnalyticsIcon,
  LeaderDashboardIcon,
  LeaderLogIcon,
  LeaderPeopleIcon,
  LeaderPrioritiesIcon,
  PathIcon,
  PeopleIcon,
  RoleIqIcon,
  SandboxIcon,
  SkillIqIcon,
  TechFoundationsIcon,
  TfArtificialIntelligenceIcon,
  TfDataIcon,
  TfSecurityIcon,
} from '@pluralsight/icons';
import React from 'react';

import type { ReactElement } from 'react';

export const ITEM_TO_ICON_MAP: { [key: string]: ReactElement } = {
  account: <LeaderAccountIcon aria-hidden="true" />,
  analytics: <LeaderAnalyticsIcon aria-hidden="true" />,
  'browse-all': <BrowseIcon aria-hidden="true" />,
  certifications: <CertificationIcon aria-hidden="true" />,
  channels: <ChannelIcon aria-hidden="true" />,
  'domain-page-business': <BriefcaseIcon aria-hidden="true" />,
  'domain-page-cloud': <CloudIcon aria-hidden="true" />,
  'domain-page-data': <TfDataIcon aria-hidden="true" />,
  'domain-page-generative-ai': (
    <TfArtificialIntelligenceIcon aria-hidden="true" />
  ),
  'domain-page-it-ops': <GearIcon aria-hidden="true" />,
  'domain-page-security': <TfSecurityIcon aria-hidden="true" />,
  'domain-page-software-dev': <CodeIcon aria-hidden="true" />,
  'home-learner': <HomeIcon aria-hidden="true" />,
  'leader-hub': <LeaderDashboardIcon aria-hidden="true" />,
  log: <LeaderLogIcon aria-hidden="true" />,
  paths: <PathIcon aria-hidden="true" />,
  people: <LeaderPeopleIcon aria-hidden="true" />,
  priorities: <LeaderPrioritiesIcon aria-hidden="true" />,
  'role-iq-leader': <RoleIqIcon aria-hidden="true" />,
  'role-iq-learner': <RoleIqIcon aria-hidden="true" />,
  sandboxes: <SandboxIcon aria-hidden="true" />,
  settings: <GearIcon aria-hidden="true" />,
  'skill-iq': <SkillIqIcon aria-hidden="true" />,
  teams: <PeopleIcon aria-hidden="true" />,
  'tech-foundations': <TechFoundationsIcon aria-hidden="true" />,
};
