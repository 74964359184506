// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer__container {
  padding: var(--pando-spacing-4) var(--pando-spacing-6);
  background: var(--pando-colors-neutral-surface-initial);
}
@media (max-width: 40rem) {
  .footer__container {
    padding: var(--pando-spacing-6);
  }
  .footer__container__content {
    flex-direction: column;
    gap: var(--pando-padding-lg);
  }
  .footer__container__company-info {
    justify-content: flex-start;
  }
}
.footer__container__logo {
  color: var(--pando-colors-neutral-text-100);
  font-size: var(--pando-font-sizes-xs);
  font-style: normal;
  font-weight: var(--pando-font-weights-medium);
  line-height: var(--pando-line-heights-relaxed);
}
.footer__container__link {
  color: var(--pando-colors-neutral-text-100);
  font-size: var(--pando-font-sizes-xs);
  font-style: normal;
  font-weight: var(--pando-font-weights-medium);
  line-height: var(--pando-line-heights-relaxed);
  text-decoration-line: underline;
  cursor: pointer;
}

.language-selector {
  display: flex;
  flex-direction: row;
  border: 1px solid #5145a6;
  border-radius: 5px;
  padding: 2px 10px;
}`, "",{"version":3,"sources":["webpack://./src/features/footer/components/footer/footer.scss"],"names":[],"mappings":"AAUE;EACE,sDAAA;EACA,uDAAA;AATJ;AAYM;EALJ;IAMM,+BAAA;EATN;EAWM;IACE,sBAAA;IACA,4BAAA;EATR;EAYM;IACE,2BAAA;EAVR;AACF;AAeI;EA5BF,2CAAA;EACA,qCAAA;EACA,kBAAA;EACA,6CAAA;EACA,8CAAA;AAgBF;AAYI;EAhCF,2CAAA;EACA,qCAAA;EACA,kBAAA;EACA,6CAAA;EACA,8CAAA;EA8BI,+BAAA;EACA,eAAA;AANN;;AAWA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,iBAAA;AARF","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n@mixin common-footer-style {\n  color: var(--pando-colors-neutral-text-100);\n  font-size: var(--pando-font-sizes-xs);\n  font-style: normal;\n  font-weight: var(--pando-font-weights-medium);\n  line-height: var(--pando-line-heights-relaxed);\n}\n\n.footer {\n  &__container {\n    padding: var(--pando-spacing-4) var(--pando-spacing-6);\n    background: var(--pando-colors-neutral-surface-initial);\n    \n    \n      @media (max-width: $pando-breakpoints-sm) {\n        padding: var(--pando-spacing-6);\n\n        &__content {\n          flex-direction: column;\n          gap: var(--pando-padding-lg)\n        }\n\n        &__company-info {\n          justify-content: flex-start\n        }\n    }\n\n  \n\n    &__logo {\n      @include common-footer-style;\n    }\n\n    &__link {\n      @include common-footer-style;\n      text-decoration-line: underline;\n      cursor: pointer;\n    }\n  }\n}\n\n.language-selector {\n  display: flex;\n  flex-direction: row;\n  border: 1px solid #5145a6;\n  border-radius: 5px;\n  padding: 2px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
