import { call, getContext, put, takeLatest } from 'redux-saga/effects';

import { NavigationService } from './services';
import {
  getNavigation,
  getNavigationFailure,
  getNavigationSuccess,
  setNavigation,
} from './slice';

import type { IAdaptiveNavigationNode } from './interfaces';
import type { AxiosInstance } from 'axios';

function* getNavigationSaga() {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const navigationService = new NavigationService(httpClient);
    const navigation: IAdaptiveNavigationNode[] = yield call(
      navigationService.getNavigation,
    );

    yield put(getNavigationSuccess());
    yield put(setNavigation(navigation));
  } catch (error) {
    if (error instanceof Error) {
      yield put(getNavigationFailure(error.message));
    } else {
      yield put(getNavigationFailure('An unknown error occurred'));
    }
  }
}

export default function* navigationSagas() {
  yield takeLatest(getNavigation, getNavigationSaga);
}
