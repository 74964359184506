import { CONFIGURATION } from '../../../config';

import type { AxiosInstance, AxiosResponse } from 'axios';
import type { IAdaptiveNavigationNode } from './interfaces';

const { NAVIGATION } = CONFIGURATION.COMMON.API;

export class NavigationService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  getNavigation = async () => {
    try {
      const response = await this.httpClient.get<
        AxiosResponse<IAdaptiveNavigationNode[]>
      >(`${NAVIGATION.BASE_URL}${NAVIGATION.PATH}`);

      return response.data;
    } catch (error) {
      console.error('Error getting Navigation:', error);

      throw error;
    }
  };
}
