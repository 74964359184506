// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation-item {
  color: var(--pando-colors-neutral-text-200);
  border-radius: var(--pando-radii-xs);
  cursor: pointer;
  overflow: hidden;
  padding: var(--pando-spacing-2) var(--pando-spacing-4);
  position: relative;
  width: 100%;
}
.navigation-item:hover {
  color: var(--pando-colors-neutral-text-300);
  background-color: var(--pando-colors-neutral-border-100);
}
.navigation-item__content {
  align-items: center;
}
.navigation-item__icon {
  margin-right: var(--pando-spacing-2);
}
.navigation-item.is-active {
  color: var(--pando-colors-neutral-text-300);
  background-color: var(--pando-colors-neutral-border-200);
}
.navigation-item.is-active::before {
  background-color: var(--pando-colors-danger-border-initial);
  content: "";
  height: 100%;
  right: 0;
  position: absolute;
  top: 0;
  width: var(--pando-spacing-1);
}`, "",{"version":3,"sources":["webpack://./src/features/navigation/components/navigation-item/navigation-item.scss"],"names":[],"mappings":"AACA;EACE,2CAAA;EACA,oCAAA;EACA,eAAA;EACA,gBAAA;EACA,sDAAA;EACA,kBAAA;EACA,WAAA;AAAF;AAEE;EACE,2CAAA;EACA,wDAAA;AAAJ;AAGE;EACE,mBAAA;AADJ;AAIE;EACE,oCAAA;AAFJ;AAKE;EACE,2CAAA;EACA,wDAAA;AAHJ;AAKI;EACE,2DAAA;EACA,WAAA;EACA,YAAA;EACA,QAAA;EACA,kBAAA;EACA,MAAA;EACA,6BAAA;AAHN","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.navigation-item {\n  color: var(--pando-colors-neutral-text-200);\n  border-radius: var(--pando-radii-xs);\n  cursor: pointer;\n  overflow: hidden;\n  padding: var(--pando-spacing-2) var(--pando-spacing-4);\n  position: relative;\n  width: 100%;\n\n  &:hover {\n    color: var(--pando-colors-neutral-text-300);\n    background-color: var(--pando-colors-neutral-border-100);\n  }\n\n  &__content {\n    align-items: center;\n  }\n\n  &__icon {\n    margin-right: var(--pando-spacing-2);\n  }\n\n  &.is-active {\n    color: var(--pando-colors-neutral-text-300);\n    background-color: var(--pando-colors-neutral-border-200);\n\n    &::before {\n      background-color: var(--pando-colors-danger-border-initial);\n      content: \"\";\n      height: 100%;\n      right: 0;\n      position: absolute;\n      top: 0;\n      width: var(--pando-spacing-1);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
