// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../node_modules/@pluralsight/react-ng/src/static-css/styles.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../node_modules/@pluralsight/design-tokens/build/css/_variables.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./shared/styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer reset, base, tokens, recipes, utilities;

html {
  background-color: var(--pando-colors-neutral-surface-initial);
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAKA,8CAA8C;;AAE9C;EACE,6DAA6D;AAC/D","sourcesContent":["@import url(\"@pluralsight/react-ng/styles.css\");\n@import url(\"@pluralsight/design-tokens/variables.css\");\n\n@import url(\"./shared/styles.css\");\n\n@layer reset, base, tokens, recipes, utilities;\n\nhtml {\n  background-color: var(--pando-colors-neutral-surface-initial);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
