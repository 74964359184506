import React from 'react';
import './plan-switch.scss';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon } from '@pluralsight/icons';

const PlanSwitch = () => {
  const { t } = useTranslation();

  return (
    <div className="plan-switch__container">
      <div className="plan-switch__button">
        {t('planSwitch.changePlan')}
        <ChevronDownIcon className="plan-switch__chevron-down-icon" />
      </div>
    </div>
  );
};

export default PlanSwitch;
