import React, { useEffect, useRef } from 'react';
import './drawer.scss';
import cs from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { PandoBreakpointsSm } from '@pluralsight/design-tokens';
import ReactDOM from 'react-dom';

import Backdrop from '../backdrop/backdrop';

interface DrawerProps {
  isOpen: boolean;
  onToggle: VoidFunction;
  id: string;
  button: React.ReactNode;
  children: React.ReactNode;
}

const Drawer = ({
  isOpen,
  button,
  id,
  onToggle,
  children,
}: DrawerProps): JSX.Element => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const portalTarget = document.getElementById('backdrop');

  const isMobileScreen = useMediaQuery({
    query: `(max-width: ${PandoBreakpointsSm})`,
  });

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      onToggle();

      buttonRef.current?.focus();
    }
  };

  useEffect(() => {
    if (!isOpen) return;

    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      const isInsideDrawer = drawerRef.current?.contains(target);
      const isToggleButton = target.closest('.drawer-container__button');

      if (!isInsideDrawer && !isToggleButton) {
        onToggle();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      onKeyDown={handleKeyDown}
      className={cs('drawer-container', {
        open: isOpen,
      })}
    >
      <button
        id={id}
        ref={buttonRef}
        onClick={(e) => {
          e.preventDefault();
          onToggle();
        }}
        className="drawer-container__button"
        aria-expanded={isOpen}
      >
        {button}
      </button>

      {isOpen && <Backdrop onClick={onToggle} aria-hidden="true" />}

      {portalTarget &&
        ReactDOM.createPortal(
          <div
            ref={drawerRef}
            className={cs('drawer-container__content', {
              'full-width': isMobileScreen,
              open: isOpen,
            })}
          >
            {children}
          </div>,
          portalTarget,
        )}
    </div>
  );
};

export default Drawer;
