type EventCallback = (data: any) => void;

type Events = {
  [eventName: string]: EventCallback[];
};

export class Index {
  private events: Events = {};

  subscribe(eventName: string, callback: EventCallback): () => void {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }

    this.events[eventName].push(callback);

    return () => {
      // Unsubscribe function
      this.events[eventName] = this.events[eventName].filter(
        (cb) => cb !== callback,
      );
    };
  }

  publish(eventName: string, data: any): void {
    if (this.events[eventName]) {
      this.events[eventName].forEach((callback) => callback(data));
    }
  }
}

const eventBus = new Index();

export default eventBus;
