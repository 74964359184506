import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './backdrop.scss';

interface BackdropProps {
  onClick?: () => void;
  className?: string;
}

const Backdrop = ({
  onClick,
  className = '',
}: BackdropProps): JSX.Element | null => {
  const portalTarget = document.getElementById('backdrop');

  if (!portalTarget) return null;

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return ReactDOM.createPortal(
    <div className={`backdrop ${className}`} onClick={onClick} />,
    portalTarget,
  );
};

export default Backdrop;
