import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { GlobalState, User } from './interface';

const INITIAL_STATE: GlobalState = {
  isAuthenticated: false,
  error: null,
  user: null,
  loading: false,
};

const globalSlice = createSlice({
  name: 'global',
  initialState: INITIAL_STATE,
  reducers: {
    setAuthentication: (state, action: PayloadAction<boolean>) => {
      if (state === null) {
        return { ...INITIAL_STATE, isAuthenticated: action.payload };
      }

      state.isAuthenticated = action.payload;
    },
    setUser: (state) => {
      if (state === null) {
        return { ...INITIAL_STATE, loading: true, error: null };
      }

      state.error = null;
      state.loading = true;
    },
    setUserSuccess: (state, action: PayloadAction<User | null>) => {
      if (state === null) {
        return { ...INITIAL_STATE, user: action.payload, loading: false };
      }

      state.user = action.payload;
      state.loading = false;
    },
    setUserFailure: (state, action: PayloadAction<string>) => {
      if (state === null) {
        return { ...INITIAL_STATE, error: action.payload, loading: false };
      }

      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { setAuthentication, setUser, setUserSuccess, setUserFailure } =
  globalSlice.actions;

export default globalSlice.reducer;
