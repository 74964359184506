import { call, put, take, takeLatest } from 'redux-saga/effects';

import { createEventBusChannel } from './channels/createEventBusChannel';
import {
  setAuthentication,
  setUser,
  setUserFailure,
  setUserSuccess,
} from './slice';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { AxiosInstance } from 'axios';
import type { EventChannel } from 'redux-saga';
import type { ActionPattern } from 'redux-saga/effects';
import type { GlobalState, User } from './interface';

function* setUserSaga(
  action: PayloadAction<User | null, string, { extra: AxiosInstance }>,
): Generator {
  try {
    const response: User | null = action.payload;

    yield put(setUserSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(setUserFailure(error.message));
    } else {
      yield put(setUserFailure('An unknown error occurred'));
    }
  }
}

function* handleAuthenticationChange(data: GlobalState): Generator {
  yield put({ type: setAuthentication.type, payload: data.isAuthenticated });
  yield put({ type: setUser.type, payload: data.user });
}

function* watchAuthenticationEvent(): Generator {
  try {
    const channel = (yield call(
      createEventBusChannel,
      'authChanged',
    )) as EventChannel<GlobalState>;

    while (true) {
      const data = (yield take(channel)) as GlobalState;

      yield call(handleAuthenticationChange, data as GlobalState);
    }
  } catch (error) {
    console.error('Error watching authentication event', error);
  }
}

// TODO: FIX ESLINT ISSUES
// eslint-disable-next-line require-yield
function* handleAuthStatusResponse(data: GlobalState): Generator {
  (window as any).globalEventBus.publish('authStatusResponse', {
    isAuthenticated: data.isAuthenticated,
    user: data.user,
  });
}

function* watchAuthStatusRequest() {
  try {
    const channel: ActionPattern = yield call(
      createEventBusChannel,
      'requestAuthStatus',
    );

    while (true) {
      yield take(channel);

      const globalState = (window as any).getGlobalState();

      yield call(handleAuthStatusResponse, globalState);
    }
  } catch (error) {
    console.error('Error watching auth status event', error);
  }
}

export default function* globalSaga(): Generator {
  yield takeLatest(setUser.type, setUserSaga);
  yield call(watchAuthenticationEvent);
  yield call(watchAuthStatusRequest);
}
