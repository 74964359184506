import { eventChannel } from 'redux-saga';

import eventBus from '../../../../shared/events';

import type { EventChannel } from 'redux-saga';

const initEventBus = () => {
  (window as any).globalEventBus = eventBus;
};

export const createEventBusChannel = (eventName: string): EventChannel<any> => {
  if (!(window as any).globalEventBus) initEventBus();

  return eventChannel((emit) => {
    const handler = (data: any) => {
      emit(data);
    };

    const unsubscribe = (window as any).globalEventBus
      ? (window as any).globalEventBus.subscribe(eventName, handler)
      : () => {};

    return () => {
      unsubscribe();
    };
  });
};
