// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation {
  background-color: var(--pando-colors-neutral-surface-200);
  flex-direction: column;
}
.navigation__item-container {
  position: relative;
}
.navigation__item-container.learning-domain {
  margin-left: var(--pando-spacing-6);
}
.navigation__icon {
  position: absolute;
  right: var(--pando-spacing-2);
  top: 50%;
  transform: translateY(-50%);
}
.navigation__divider {
  border-color: var(--pando-colors-neutral-border-initial);
  margin: var(--pando-spacing-4);
}
.navigation__learning-header {
  align-items: center;
  color: var(--pando-colors-neutral-text-100);
  display: flex;
  justify-content: space-between;
  padding: 0 var(--pando-spacing-4) var(--pando-spacing-2);
}`, "",{"version":3,"sources":["webpack://./src/features/navigation/components/navigation/navigation.scss"],"names":[],"mappings":"AACA;EACE,yDAAA;EACA,sBAAA;AAAF;AAEE;EACE,kBAAA;AAAJ;AAGE;EACE,mCAAA;AADJ;AAIE;EACE,kBAAA;EACA,6BAAA;EACA,QAAA;EACA,2BAAA;AAFJ;AAKE;EACE,wDAAA;EACA,8BAAA;AAHJ;AAME;EACE,mBAAA;EACA,2CAAA;EACA,aAAA;EACA,8BAAA;EACA,wDAAA;AAJJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.navigation {\n  background-color: var(--pando-colors-neutral-surface-200);\n  flex-direction: column;\n\n  &__item-container {\n    position: relative;\n  }\n\n  &__item-container.learning-domain {\n    margin-left: var(--pando-spacing-6);\n  }\n\n  &__icon {\n    position: absolute;\n    right: var(--pando-spacing-2);\n    top: 50%;\n    transform: translateY(-50%);\n  }\n\n  &__divider {\n    border-color: var(--pando-colors-neutral-border-initial);\n    margin: var(--pando-spacing-4);\n  }\n\n  &__learning-header {\n    align-items: center;\n    color: var(--pando-colors-neutral-text-100);\n    display: flex;\n    justify-content: space-between;\n    padding: 0 var(--pando-spacing-4) var(--pando-spacing-2);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
