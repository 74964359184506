// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plan-switch__container {
  position: relative;
}
.plan-switch__button {
  align-items: center;
  background-color: var(--pando-colors-action-bg-100-active);
  cursor: not-allowed;
  display: flex;
  height: var(--pando-spacing-10);
  justify-content: space-between;
  margin-bottom: var(--pando-spacing-8);
  padding: 0 var(--pando-spacing-6);
}
.plan-switch__chevron-down-icon {
  height: var(--pando-spacing-6);
  width: var(--pando-spacing-6);
}`, "",{"version":3,"sources":["webpack://./src/features/navigation/components/plan-switch/plan-switch.scss"],"names":[],"mappings":"AAEE;EACE,kBAAA;AADJ;AAIE;EACE,mBAAA;EACA,0DAAA;EACA,mBAAA;EACA,aAAA;EACA,+BAAA;EACA,8BAAA;EACA,qCAAA;EACA,iCAAA;AAFJ;AAKE;EACE,8BAAA;EACA,6BAAA;AAHJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.plan-switch {\n  &__container {\n    position: relative;\n  }\n\n  &__button {\n    align-items: center;\n    background-color: var(--pando-colors-action-bg-100-active);\n    cursor: not-allowed;\n    display: flex;\n    height: var(--pando-spacing-10);\n    justify-content: space-between;\n    margin-bottom: var(--pando-spacing-8);\n    padding: 0 var(--pando-spacing-6);\n  }\n\n  &__chevron-down-icon {\n    height: var(--pando-spacing-6);\n    width: var(--pando-spacing-6);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
